import React, {Component, Fragment} from 'react'
import { connect } from 'react-redux'
import onClickOutside from 'react-onclickoutside'
import { withRouter } from 'react-router-dom';
import { signOutAction } from 'redux/reducers/app'

import {
  ICON_HOME,
  ICON_COURSE,
  ICON_TODO,
  ICON_NOTIFICATION,
  ICON_AUTH,
  ICON_FEEDBACK,
  } from 'redux/constants/header.js'

import './HeaderIconContent.css'
import {getVersion} from "../../../settings";
import {getUser, signOut} from "../../../api/Auth";
import { Redirect } from 'react-router';

const VERSION = getVersion();
const mapDispatchToProps = {signOutAction}

const mapStateToProps = (state) => ({
  user: state.app.get('user')
});

@connect(mapStateToProps, mapDispatchToProps)
class HeaderIconContent extends Component {
  static getDerivedStateFromProps(props, state) {
    return {
      isOpen: props.activeIcon,
    }
  }

  constructor(props) {
    super(props);
    this.state = {}
  }

  renderAuthList = () => {
    const {
      signOutAction
    } = this.props;

    const user = getUser();

    return (
      <Fragment>
        <li
          className="HeaderIconContent-item"
        >
          {user ? user.uid: ""}
        </li>
        <li
          className="HeaderIconContent-item"
          onClick={signOutAction}
          >
          登出
        </li>
        <li
          className="HeaderIconContent-item"
        >
          <span style={{color: "#D6D6D6"}}>
            版本 {VERSION}
          </span>
        </li>
      </Fragment>
    )
  }

  showNotificationAction (id) {
    if (!id || id.trim() === '') {
      return;
    }
    const {user} = this.props;
    if (!user) {
      return;
    }
  }

  renderNotificationList = content => {
    return (
      <Fragment>
        <header
          className="HeaderIconContent-header"
          >
          {content.header} ({content.amount})
        </header>
        {
          content.items.map(item => <li key={item.id} className="HeaderIconContent-item"
                                        onClick={this.showNotificationAction.bind(this, item.id)} >{item.name}</li>)
        }
      </Fragment>
    )
  }

  renderContentList = content => {
    return (
      <Fragment>
        <header
          className="HeaderIconContent-header"
        >
          {content.header} ({content.amount})
        </header>
        {
          content.items.map(item => <li key={item.id} className="HeaderIconContent-item">{item.name}</li>)
        }
      </Fragment>
    )
  }

  getNotification = () => {
    const {notifications} = this.props;

    if (notifications.length === 0) {
      return {
        header: '沒有通知訊息',
        amount: 0,
        items: []
      }
    }

    const items = [];
    notifications.forEach(n => {
      items.push({
        id: n.id,
        name: n.title,
      })
    })

    return {
      header: '未讀通知',
      amount: notifications.length,
      items: items,
    }
  };

  render() {

    const user = getUser();
    if (!user) {
      return <Redirect to={"/login"} />
    }

    const headerData = {
      [ICON_NOTIFICATION]: this.getNotification()
    };

    const {
      activeIcon
    } = this.props;
    const {
      isOpen
    } = this.state;

    const content = headerData[activeIcon]

    if (activeIcon === ICON_HOME) return null

    if (activeIcon === ICON_FEEDBACK) return null

    if(!isOpen) return null

    if (activeIcon === ICON_NOTIFICATION) {
      return (<ul className="HeaderIconContent">
        {this.renderNotificationList(content)}
      </ul>);
    }

    if (activeIcon === ICON_AUTH) {
      return (<ul className="HeaderIconContent">
        {this.renderAuthList(content)}
      </ul>);
    }

    return (<ul className="HeaderIconContent">
      {this.renderContentList(content)}
    </ul>);
  }
}

export default withRouter(HeaderIconContent);
