import React from 'react';

import {
  Route,
  Redirect,
} from 'react-router-dom';

export default class RoleChecker extends React.PureComponent {

  constructor(props) {
    super(props);
  }

  render() {
    const cacheUser = localStorage.getItem("user");
    const user = cacheUser ? JSON.parse(cacheUser): undefined;

    const isAuthenticated = user ? true : false;

    console.log('isAuthenticated', isAuthenticated, user);

    return (
      <Route
        {...rest}
        render={props => (isAuthenticated ?
          <Component {...props} /> :
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />)
        }
      />
    );
  }
}
