export const GET_COURSE = 'course/GET_COURSE';
export const GET_COURSE_SUC = 'course/GET_COURSE_SUC';
export const GET_COURSE_ERR = 'course/GET_COURSE_ERR';

export const SAGA_GET_COURSE = 'course/SAGA_GET_COURSE';

export const COURSE_TYPES = ['all', 'python', 'microbit', 'pygame', 'c', 'cpp', 'video'];
export const COURSE_TYPE_LABELS = {'all': 'ALL', 'python':'Python', 'microbit':'Microbit', 'pygame':'Pygame', 'c':'C', 'cpp':'C++', 'java':'Java', 'video': 'Video'};
export const COURSE_LEVEL_LABELS = {'begin': '初級班', 'medium':'中級班', 'advanced':'進階班'};
export const getCourseType = (index) => {
  return COURSE_TYPES[index];
}

export const getCourseTypeLabel = (type) => {
  return COURSE_TYPE_LABELS[type];
}

export const COURSE_TYPE_DROPDOWN = [
  { value: 'python', label: 'Python' },
  { value: 'microbit', label: 'Microbit' },
  { value: 'pygame', label: 'Pygame' },
  { value: 'c', label: 'C' },
  { value: 'cpp', label: 'C++' },
  { value: 'video', label: 'Video' },
]
