export const GET_VERSION = 'app/GET_VERSION';

export const INIT = 'app/INIT';
export const INIT_SUC = 'app/INIT_SUC';
export const INIT_ERR = 'app/INIT_ERR';

export const SIGNIN = 'auth/SIGNIN';
export const SIGNIN_SUC = 'auth/SIGNIN_SUC';
export const SIGNIN_ERR = 'auth/SIGNIN_ERR';
export const SIGNOUT = 'auth/SIGNOUT';

export const GETUSER = 'auth/GETUSER';

export const SAGA_SIGNIN = 'auth/SAGA_SIGNIN';
export const SAGA_SIGNOUT = 'auth/SAGA_SIGNOUT';
export const SAGA_GETUSER = 'auth/SAGA_GETUSER';
