export const ICON_HOME = 'home';
export const ICON_CODING = 'coding'
export const ICON_COURSE = 'course';
export const ICON_TODO = 'todo';
export const ICON_NOTIFICATION = 'notification';
export const ICON_AUTH = 'auth';
export const ICON_FEEDBACK = 'feedback'

export const ICON_NAME_MAPPING = {
  [ICON_HOME]: 'MdHome',
  [ICON_CODING]: 'MdCoding',
  [ICON_COURSE]: 'MdMenu',
  [ICON_TODO]: 'MdCheckBox',
  [ICON_NOTIFICATION]: 'MdNotifications',
  [ICON_FEEDBACK]: 'MdFeedback',
}
