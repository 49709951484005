import React, { Component } from 'react';
import { Link } from "react-router-dom";

import './Header.css';
import headerLogo from 'design/images/logo.png'
import imgTaipei101 from 'design/images/taipei101.png'
import {getUser} from "../../../api/Auth";
import LinearLayout from '../../../components/common/LinearLayout';

export default class Header extends Component {

  render() {
    const {
      headerLabel,
      children,
    } = this.props;

    const user = getUser();

    // render menu when user is authorized
    return (
      <header className={ (!user | user == null) ? "Header-dark" : "Header-dark" }>
        {/* <h1 className="Header-label">{headerLabel}</h1> */}
        <LinearLayout orientation={'horizontal'} align={'left'}>

          <LinearLayout orientation={'vertical'} width={"250px"} align={'center'}>
            <div style={{fontSize: "20pt", color: "#ffffff"}}>
              English || 中文
            </div>
          </LinearLayout>
        </LinearLayout>
      </header>
    );
  }
}

