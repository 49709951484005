import React, {Component} from 'react'
import Icon from 'components/Icon'
import Badge from 'components/Badge/Badge'

import {
  ICON_HOME,
  ICON_CODING,
  ICON_COURSE,
  ICON_TODO,
  ICON_NOTIFICATION,
  ICON_AUTH,
  ICON_FEEDBACK,
  ICON_NAME_MAPPING
} from 'redux/constants/header.js'


import './HeaderIconList.css';
import './HeaderDetail.css'
import {Image} from "react-bootstrap";
import feedbackIcon from "../../../../design/images/header/header-feedback.svg";
import codingIcon from "../../../../design/images/icons/Coding.png";
import PropTypes from "prop-types";
import Immutable from "immutable";
import {getUser} from "../../../api/Auth";

class HeaderIconList extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {onClickIcon, notificationCount} = this.props;

    const user = getUser();

    return (
      <ul className="HeaderIconList">
        <li
          className="HeaderIconList-item"
          onClick={(e) => onClickIcon(ICON_HOME,e)}
        >
          <Icon name={ICON_NAME_MAPPING[ICON_HOME]} color="#5CD391" />
        </li>

        <li
          className="HeaderIconList-item"
          onClick={(e) => onClickIcon(ICON_NOTIFICATION,e)}
        >
          <Icon name={ICON_NAME_MAPPING[ICON_NOTIFICATION]} color={notificationCount && notificationCount > 0 ? "#F65248" : "#6F8190"} />
          {notificationCount && notificationCount > 0 ? (<Badge
            backgroundColor="#F65248"
            color="#29354C"
            amount={notificationCount}
            className="HeaderIconList-item-badge"
          />):''}
        </li>

        <li
          className="HeaderIconList-item"
          style={{marginRight:"50px"}}
          onClick={(e) => onClickIcon(ICON_AUTH,e)}
        >
          <Image
            src={user && user.photo ? user.photo : ''}
            className="HeaderIconList-item--auth"
            circle
          />
        </li>
      </ul>
    )
  }
}

HeaderIconList.propTypes = {
  user: PropTypes.instanceOf(Immutable.Iterable),
}

HeaderIconList.defaultProps = {
  user: null,
}

export default HeaderIconList;

