import { takeEvery, select, call, put, spawn } from 'redux-saga/effects';
import * as cons from '../constants/course';

export function* watchGetCourse() {
  yield takeEvery(cons.SAGA_GET_COURSE, function* ({ payload = {} }) {
    // try {
    //   const courseService = silkrodeApi.getCourseService();
    //   yield put({ type: cons.GET_COURSE });
    //   const courseId = payload.courseId;
    //
    //   const course = yield call([courseService, 'getCourse'], courseId);
    //
    //   yield put({ type: cons.GET_COURSE_SUC, course });
    // } catch (error) {
    //   yield put({ type: cons.GET_COURSE_ERR, error });
    //   throw error;
    // }
  });
}

export default [
  watchGetCourse,
];
