
export function get(url) {
  return fetch(url, {
    method: 'GET',
  }).then((response) => {
    return response.json();
  });
}

export function post(url, headers, body) {
  return fetch(url, {
    method: 'POST',
    body: body ? JSON.stringify(body) : undefined,
    headers: new Headers(headers ? headers: {
      'Content-Type': 'application/json'
    })
  }).then((response) => {
    return response.json();
  });
}

export function put(url, headers, body) {
  return fetch(url, {
    method: 'PUT',
    body: body ? JSON.stringify(body) : undefined,
    //headers: new Headers({
    //  'Content-Type': 'application/json'
    //})
    headers: new Headers(headers)
  }).then((response) => {
    return response.json();
  });;
}

export function deleteRequest(url, headers, body) {
  return fetch(url, {
    method: 'DELETE',
    body: body ? JSON.stringify(body) : undefined,
    headers: new Headers(headers)
  }).then((response) => {
    return response.json();
  });;
}

export async function patch(url, headers, body) {
  let response = await fetch(url, {
    method: 'PATCH',
    body: body ? JSON.stringify(body) : undefined,
    headers: new Headers(headers)
  })
  return response.json();

  /*return fetch(url, {
    method: 'PATCH',
    body: body ? JSON.stringify(body) : undefined,
    //headers: new Headers({
    //  'Content-Type': 'application/json'
    //})
    headers: new Headers(headers)
  }).then((response) => {
    console.log(response)
    return response.json();
  });;*/
}

