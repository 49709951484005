import React, {Component} from 'react'
import { withRouter } from "react-router-dom"
import HeaderIconList from 'containers/App/Header/HeaderIconList'
import HeaderIconContent from 'containers/App/Header/HeaderIconContent'
import html2canvas from 'html2canvas';

import onClickOutside from 'react-onclickoutside'

import {
  ICON_HOME,
  ICON_CODING,
  ICON_COURSE,
  ICON_TODO,
  ICON_NOTIFICATION,
  ICON_AUTH,
  ICON_FEEDBACK,
  ICON_NAME_MAPPING,
} from 'redux/constants/header.js'

import './HeaderDetail.css'

@onClickOutside
class HeaderDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIcon: '',
      // following state used for Feedback screenshot
      screenshot: undefined,
      notifications:[],
      showFeedback:false,
    };

    this.handleClickOutside = this.handleClickOutside.bind(this)
    this.getAppScreenshot = this.getAppScreenshot.bind(this)
  }

  componentDidMount() {
    window.addEventListener("click",() => {
      this.setState({activeIcon: ''})
    })
  }

  onClickIcon = (iconName,e) => {
    // resetComment state to hide content block if currently displaying
    e.stopPropagation();
    if (this.state.activeIcon === iconName) {
      this.setState(() => {
        return {
          activeIcon: '',
        }
      })
      return
    }


    if (iconName === ICON_HOME) {
       this.props.history.push(`/`);
      return;
    }

    if (iconName === ICON_FEEDBACK) {
      // TODO: show progresss
      // this.setState({
      //   isShowProgress: true,
      // })
      //this.getAppScreenshot()
      return
    }

    this.setState(prevState => {
      return {
        activeIcon: iconName
      }
    })
  }

  handleClickOutside = () => {
    /*if (this.state.activeIcon) {
      //this.setState({activeIcon: ''})
    }*/
  };

  /*closeFeedbackModal = () => {
    console.log("closeFeedbackModal")
    this.setState({activeIcon: ''})
  }*/

  getAppScreenshot() {
    html2canvas(document.body, {}).then((canvas) => {
      // change state to render feedback window open after screenshot the app view
      /*this.setState({
        activeIcon: ICON_FEEDBACK,
      })*/

      document.getElementById('feedback-screenshot').appendChild(canvas)

      // change canvas to blob to save image as binary
      canvas.toBlob((blob)=>{
        this.setState({
          screenshot: blob,
        })
      });
    })
  }

  openFeedback = async () => {
    await this.getAppScreenshot();
    await this.setState({
        showFeedback: true,
      })
  }

  closeFeedback = () => {
    this.setState({
      showFeedback: false,
    })
  }

  render() {
    // console.log("HeaderDetail# render#", this.state.activeIcon)
    const { activeIcon } = this.state;

    const { notifications } = this.props;

    return (
      <div className="HeaderDetail">
        <HeaderIconList
          activeIcon={activeIcon}
          onClickIcon={this.onClickIcon.bind(this)}
          notificationCount={notifications ? notifications.length:0}
          openFeedback={this.openFeedback.bind(this)}
          />
        <HeaderIconContent
          activeIcon={activeIcon}
          notifications={notifications ? notifications:[]}
          closeHeaderContent={this.handleClickOutside.bind(this)}
          />
        {this.state.showFeedback ? this.renderFeedbackModal(ICON_FEEDBACK) : ''}
      </div>
    )
  }
}

export default withRouter(HeaderDetail);

