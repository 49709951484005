import {time2Text} from "./time";

export function logd(...args) {
  const len = args.length;
  switch(len) {
    case 1:
      console.log(`[DEBUG] ${time2Text(Date.now())} ${args[0]}`);
      break;
    case 2:
      console.log(`[DEBUG] ${time2Text(Date.now())} ${args[0]}`, args[1]);
      break;
  }
}

export function logi(...args) {
  const len = args.length;
  switch(len) {
    case 1:
      console.log(`[INFO] ${time2Text(Date.now())} ${args[0]}`);
      break;
    case 2:
      console.log(`[INFO] ${time2Text(Date.now())} ${args[0]}`, args[1]);
      break;
  }
}

export function log(...args) {
  const len = args.length;
  switch(len) {
    case 1:
      console.log(`[LOG] ${time2Text(Date.now())} ${args[0]}`);
      break;
    case 2:
      console.log(`[LOG] ${time2Text(Date.now())} ${args[0]}`, args[1]);
      break;
  }
}

export function loge(tag, msg) {
  console.error(`${time2Text(Date.now())} ${tag}`, msg ? msg:"")
}
