import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form'

import app from './app';
import course from './course';
import excercise from './excercise';
import search from './search';
import notification from './notification';

export default () => {
  const appReducer = combineReducers({
    app,
    course,
    excercise,
    form: formReducer,
    search,
    notification,
  });

  return (state, action) => {
    return appReducer(state, action);
  };
};
