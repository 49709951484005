import React from "react";
import { Col, Row, ProgressBar } from "react-bootstrap";

class InProgress extends React.Component {
  render() {
    return (
      <Row style={{ hieght: "90vh", padding: "35vh 25% 0 25%" }}>
        <Col xs={12} md={12}>
          <h4>請稍候...</h4>
          <ProgressBar
            striped
            bsStyle="warning"
            style={{ width: "100%", height: "35px" }}
            active
            now={100}
          />
        </Col>
      </Row>
    );
  }
}

export default InProgress;
