function pad(num) {
  return ('0' + num).slice(-2);
}

export function mmss(secs) {
  const minutes = Math.floor(secs / 60);
  const _secs = secs % 60;
  return `${pad(minutes)}:${pad(_secs)}`;
};

export function hhmmss(secs, labelH, labelM, labelS) {
  let minutes = Math.floor(secs / 60);
  let hours = Math.floor(minutes / 60)
  minutes = minutes % 60;
  let theSecs = Math.floor(secs % 60);
  if (hours == 0) {
    return `00:${pad(minutes < 0 ? 0:minutes)}${labelM ? labelM:':'}${pad(theSecs < 0 ? 0:theSecs)}${labelS ? labelS:''}`;
  }

  let days = 0;
  if (hours >= 24) {
    days = Math.floor(hours/24);
    hours = hours % 24;
  }

  return `${days > 0?`${days}天`:''} ${hours < 0 ? 0:hours}${labelH ? labelH:':'}${pad(minutes < 0 ? 0:minutes)}${labelM ? labelM:':'}${pad(theSecs < 0 ? 0:theSecs)}${labelS ? labelS:''}`;
};

export function addOneZeroPrefix(num) {
  return num < 10 ? `0${num}` : `${num}`;
};

export function getNotificationTime(timestamp) {
  const date = new Date(timestamp);
  const month = addOneZeroPrefix(date.getMonth() + 1);
  const date2 = addOneZeroPrefix(date.getDate());
  const hours = addOneZeroPrefix(date.getHours());
  const mins = addOneZeroPrefix(date.getMinutes());

  return `${date.getFullYear()}年${month}月${date2}日 ${hours}:${mins}`;
};

export function time2Text(timestamp) {
  const date = new Date(timestamp);
  const month = addOneZeroPrefix(date.getMonth() + 1);
  const date2 = addOneZeroPrefix(date.getDate());
  const hours = addOneZeroPrefix(date.getHours());
  const mins = addOneZeroPrefix(date.getMinutes());
  const secs = addOneZeroPrefix(date.getSeconds());
  return `${date.getFullYear()}/${month}/${date2} ${hours}:${mins}:${secs}`;
};

export function MMDD(timestamp) {
  if (!timestamp) {
    return '';
  }
  const date = new Date(timestamp);
  const month = addOneZeroPrefix(date.getMonth() + 1);
  const date2 = addOneZeroPrefix(date.getDate());
  const hours = addOneZeroPrefix(date.getHours());
  const mins = addOneZeroPrefix(date.getMinutes());

  return `${month}月${date2}日`;
};

export function toMMDD(timestamp) {
  if (!timestamp) {
    return '';
  }
  const date = new Date(timestamp);
  const month = addOneZeroPrefix(date.getMonth() + 1);
  const date2 = addOneZeroPrefix(date.getDate());
  const hours = addOneZeroPrefix(date.getHours());
  const mins = addOneZeroPrefix(date.getMinutes());

  return `${month}/${date2}`;
};

export function toYYYYMMDDhhmmss(timestamp) {
  if (!timestamp) {
    return '';
  }
  const date = new Date(timestamp);
  const year = addOneZeroPrefix(date.getFullYear());
  const month = addOneZeroPrefix(date.getMonth() + 1);
  const date2 = addOneZeroPrefix(date.getDate());
  const hours = addOneZeroPrefix(date.getHours());
  const mins = addOneZeroPrefix(date.getMinutes());
  const secs = addOneZeroPrefix(date.getSeconds());

  return `${year}-${month}-${date2} ${hours}:${mins}:${secs}`;
};

export function getYYYYMMDD(timestamp) {
  if (!timestamp) {
    return '';
  }
  const date = new Date(timestamp);
  const year = addOneZeroPrefix(date.getFullYear());
  const month = addOneZeroPrefix(date.getMonth() + 1);
  const date2 = addOneZeroPrefix(date.getDate());
  const hours = addOneZeroPrefix(date.getHours());
  const mins = addOneZeroPrefix(date.getMinutes());

  return `${year}-${month}-${date2}`;
};

export function getTimeText() {

  const date = new Date();
  const year = addOneZeroPrefix(date.getFullYear());
  const month = addOneZeroPrefix(date.getMonth() + 1);
  const day = addOneZeroPrefix(date.getDate());
  const hours = addOneZeroPrefix(date.getHours());
  const mins = addOneZeroPrefix(date.getMinutes());
  const secs = addOneZeroPrefix(date.getSeconds());

  return `${year}-${month}-${day} ${hours}:${mins}:${secs}`;
};

export function getThisWeekTimeRange() {
  const beginTime = getMondayBeginTime(Date.now());
  const endTime = beginTime + 60 * 60 * 24 * 7 * 1000;
  return {beginTime, endTime}
}

export function getLastWeekTimeRange() {
  const beginTime = getMondayBeginTime(Date.now()) -  60 * 60 * 24 * 7 * 1000;
  const endTime = beginTime + 60 * 60 * 24 * 7 * 1000;
  return {beginTime, endTime}
}

export function getBeforeLastWeekTimeRange() {
  const beginTime = getMondayBeginTime(Date.now()) -  60 * 60 * 24 * 14 * 1000;
  const endTime = beginTime + 60 * 60 * 24 * 7 * 1000;
  return {beginTime, endTime}
}

export function getMondayBeginTime(timestamp) {
  const d = new Date(timestamp);
  const day = d.getDay();
  const diff = d.getDate() - day + (day == 0 ? -6:1);

  d.setDate(diff);
  d.setHours(0);
  d.setMinutes(0);
  d.setSeconds(0);
  return new Date(d).getTime();
}

export function getMonthBeginTime(timestamp) {
  const d = new Date(timestamp);
  return Date.parse(`${d.getFullYear()}-${d.getMonth()+1}-01`);
}

// export function getMonthEndTime(timestamp) {
//   const d = new Date(timestamp);
//   return Date.parse(`${d.getFullYear()}-${d.getMonth()+1}-${getDaysInMonth(timestamp)}`);
// }

export function daysInMonth (year, month) {
  return new Date(year, month, 0).getDate();
}

export function rangeWeek (dateStr) {
  if (!dateStr) dateStr = new Date().getTime();
  var dt = new Date(dateStr);
  dt = new Date(dt.getFullYear(), dt.getMonth(), dt.getDate());
  dt = new Date(dt.getTime() - (dt.getDay() > 0 ? (dt.getDay() - 1) * 1000 * 60 * 60 * 24 : 6 * 1000 * 60 * 60 * 24));
  return { start: dt, end: new Date(dt.getTime() + 1000 * 60 * 60 * 24 * 7 - 1) };
}

export function getDateRangeOfWeek(weekNo){
  var d1 = new Date();
  const numOfdaysPastSinceLastMonday = eval(d1.getDay()- 1);
  d1.setDate(d1.getDate() - numOfdaysPastSinceLastMonday);
  var weekNoToday = d1.getWeek();
  var weeksInTheFuture = eval( weekNo - weekNoToday );
  d1.setDate(d1.getDate() + eval( 7 * weeksInTheFuture ));
  const beginDate = new Date(d1.getFullYear(), d1.getMonth(), d1.getDate());
  var rangeIsFrom = eval(d1.getMonth()+1) +"/" + d1.getDate() + "/" + d1.getFullYear();
  d1.setDate(d1.getDate() + 6);
  const endDate = new Date(d1.getFullYear(), d1.getMonth(), d1.getDate());
  var rangeIsTo = eval(d1.getMonth()+1) +"/" + d1.getDate() + "/" + d1.getFullYear() ;
  return {begin: beginDate, end:endDate};
}

export function getWeek(dateStr) {
  if (!dateStr) {
    return 0;
  }
  return new Date(dateStr.replace("T", " ").replace("Z", "")).getWeek();
}

export function getMonthBeginWeek(timestamp) {
  const d = new Date(timestamp);
  const time = Date.parse(`${d.getFullYear()}-${d.getMonth()+1}-01`);
  const date = new Date(d.getFullYear(),d.getMonth(),1);
  return date.getWeek();
}

export function getWeekBeginTime(weekno) {
  const d = new Date(timestamp);
  return Date.parse(`${d.getFullYear()}-${d.getMonth()+1}-01`);
}

export function getWeekEndTime(weekno) {
  const d = new Date(timestamp);
  return Date.parse(`${d.getFullYear()}-${d.getMonth()+1}-01`);
}

export function dateBeginTime(timestamp) {
  const date = new Date(timestamp);
  const dateText = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} 00:00:00`;
  console.log("date beginTime:", dateText);
  return Date.parse(dateText)
}

export function dateEndTime(timestamp) {
  const date = new Date(timestamp);
  const dateText = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate() + 1} 00:00:00`;
  console.log("date beginTime:", dateText);
  return Date.parse(dateText)
}

export function todayBeginTime() {
  const date = new Date();
  const dateText = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} 00:00:00`;
  console.log("today beginTime:", dateText);
  return Date.parse(dateText)
}

export function todayEndTime() {
  const date = new Date();
  const dateText = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()+1} 00:00:00`;
  console.log("today endTime:", dateText);
  return Date.parse(dateText)
}

export function getDateText(timestamp) {
  const date = new Date(timestamp);
  return addOneZeroPrefix(date.getMonth() + 1) + "/" + addOneZeroPrefix(date.getDate());
}

export function getWeekDay(timestamp) {
  const date = new Date(timestamp);
  return getWeekText(date.getDay() - 1);
}

export function getWeekText(index) {
  switch (index) {
    case 0:
      return "星期一";
    case 1:
      return "星期二";
    case 2:
      return "星期三";
    case 3:
      return "星期四";
    case 4:
      return "星期五";
    case 5:
      return "星期六";
    case 6:
      return "星期日";
  }
  return "Unknown";
}

export function getWeekLabels(timestamp) {
  const labels = [];
  const mondayTime = getMondayBeginTime(timestamp);
  const durationOfOneDay = 1000*60*60*24;
  for (let i = 0; i < 7; i++) {
    labels.push(`${getWeekText(i)} ${getDateText(mondayTime + durationOfOneDay*i)}`);
  }
  return labels;
}


export function getMonthLabels(timestamp) {
  const labels = [];
  const beginTime = getMonthBeginTime(timestamp);
  const beginDate = new Date(beginTime);
  for (let i = 0; i < getDaysInMonth(timestamp); i++) {
    labels.push(`${beginDate.getMonth()+1}/${i+1}`);
  }
  return labels;
}

export function getMonthEndTime(timestamp) {
  const beginTime = getMonthBeginTime(timestamp);
  return beginTime + getDaysInMonth(timestamp)*24*60*60*1000;
}

export function getDaysInMonth(timestamp) {
  return getMonth(timestamp).getDate();
}

export function getMonth(timestamp) {
  let date = new Date(timestamp);
  return new Date(date.getFullYear(), date.getMonth()+1, 0);
}
