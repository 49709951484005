
import React from 'react';

import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';

import RoleChecker from './checkers/RoleChecker';

import App from './containers/App/App';

// getComponent is a function that returns a promise for a component
// It will not be called until the first mount
function asyncComponent(getComponent) {
  return class AsyncComponent extends React.Component {
    static Component = null;
    state = { Component: AsyncComponent.Component };

    constructor() {
      super();

      getComponent().then(Component => {
        AsyncComponent.Component = Component;
        this.setState({ Component });
      });
    }

    render() {
      const { Component } = this.state;
      if (Component) {
        return <Component {...this.props} />;
      }
      return null;
    }
  }
}

const ChatPage = asyncComponent(() =>
  import('./containers/DemoChatPage/ChatPage').then(module => module.default)
);

export default function () {
  return (
    <Router>
      <App>
        <Switch>
          <Route exact path="/chat" component={ChatPage} />
          <Redirect from="*" to="/chat" />
        </Switch>
      </App>
    </Router>
  );
}
