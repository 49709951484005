import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './Badge.css'

const Badge = props => {
  const { backgroundColor, color, amount, className } = props;
  const rootClassName = cx('Badge', className);

  return (
    <div
      className={rootClassName}
      style={{backgroundColor: backgroundColor, color: color}}
      >
      <span>{amount}</span>
    </div>
  );
};

Badge.propTypes = {
  backgroundColor: PropTypes.string,
  color: PropTypes.string,
  amount: PropTypes.number
};

Badge.defaultProps = {
  backgroundColor: 'gray',
  color: 'white',
  amount: 1
};

export default Badge;
