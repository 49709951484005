
export function getVersion() {
  return "v1.0.8 20191029";
};

export function isDebug() {
  return true;
};

export function getConfig(env) {
  return {
    authHost: env === 'fut' ? '35.221.163.61/api':'localhost:7070',
    host: env === 'fut' ? '35.221.163.61/api':'localhost:7070'
  }
};
