import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';

import i18n from './lang/i18n';
import route from './route';
import createStore from './redux/storeCreator';

import 'normalize.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './../design/postcss/general.scss';

const store = createStore({});
const routes = route();
const appRoot = document.getElementById('app');

ReactDOM.render(

  <Provider store={store}>
    <I18nextProvider i18n={i18n}>
      {routes}
    </I18nextProvider>
  </Provider>,
  appRoot,
);
