import { fromJS } from 'immutable';
import { createAction } from 'redux-actions';
import * as cons from '../constants/app';
import {getUser} from "../../api/Auth";

const initialState = fromJS({
  init: false,
  initSuc: false,
  initErr: false,
  user: undefined,
  signIn: false,
  signInSuc: false,
  signInErr: false,
});

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case cons.INIT: {
      return state.merge({
        init: true,
        initSuc: false,
        initErr: false,
      });
    }

    case cons.INIT_SUC: {
      return state.merge({
        init: false,
        initSuc: true,
        initErr: false,
      });
    }

    case cons.INIT_ERR: {
      return state.merge({
        init: false,
        initSuc: false,
        initErr: true,
      });
    }

    case cons.SIGNIN: {
      return state.merge({
        user: undefined,
        signIn: true,
        signInSuc: false,
        signInErr: false,
      });
    }

    case cons.SIGNIN_SUC: {
      return state.merge({
        user: action.user,
        signIn: false,
        signInSuc: true,
        signInErr: false,
      });
    }

    case cons.SIGNIN_ERR: {
      return state.merge({
        user: undefined,
        signIn: false,
        signInSuc: false,
        signInErr: true,
        signInErrorInfo: action.error,
      });
    }

    case cons.SIGNOUT: {
      return state.merge({
        user: undefined,
        signIn: false,
        signInSuc: false,
        signInErr: false,
      });
    }

    case cons.GETUSER: {
      return state.merge({
        user: action.user,
      });
    }

    default:
      return state;
  }
}

export function initAction() {
  return (dispatch, getState) => {
    dispatch({ type: cons.INIT });
    const user = getUser();
    if (!user) {
      dispatch({ type: cons.SIGNOUT });
    } else {
      dispatch({ type: cons.GETUSER, user });
    }
    dispatch({ type: cons.INIT_SUC });
  };
}

export const signInAction = createAction(cons.SAGA_SIGNIN);
export const signOutAction = createAction(cons.SAGA_SIGNOUT);
export const getUserAction = createAction(cons.SAGA_GETUSER);
