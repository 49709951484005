import { takeEvery, call, put } from 'redux-saga/effects';
import * as cons from "../constants/app";
import {getUser, signOut, signIn} from "../../api/Auth";

export function* watchInit() {
  console.log("watchInit");
  yield takeEvery(cons.SAGA_INIT, function*(payload = {}) {
    console.log("SAGA_INIT");
    try {
      yield put({ type: cons.INIT });
      yield put({ type: cons.INIT_SUC, user: getUser() });
    } catch (error) {
      yield put({ type: cons.INIT_ERR, error });
      throw error;
    }
  });
}

export function* watchSignIn() {
  console.log('watchSignIn');
  yield takeEvery(cons.SAGA_SIGNIN, function* (payload = {}) {
    console.log('watchSignIn#payload', payload);
    try {
      yield put({ type: cons.SIGNIN });
      const email = payload.payload.email;
      const password = payload.payload.password;

      const user = yield call(signIn, email, password);
      console.log('watchSignIn#user', user);

      yield put({ type: cons.SIGNIN_SUC, user: user });
    } catch (error) {
      yield put({ type: cons.SIGNIN_ERR, error });
      throw error;
    }
  });
}

export function* watchSignOut() {
  console.log('watchSignOut');
  yield takeEvery(cons.SAGA_SIGNOUT, function* (payload = {}) {
    console.log('signOut#payload', payload);
    try {
      yield put({ type: cons.SIGNOUT });
      const user = yield call(signOut);
      yield put({ type: cons.SIGNOUT });
    } catch (error) {
      yield put({ type: cons.SIGNOUT });
      throw error;
    }
  });
}

export function* watchGetUser() {
  console.log('[SAGAS] watchGetUser');
  yield takeEvery(cons.SAGA_GETUSER, function* (payload = {}) {
    console.log('[SAGAS] watchGetUser#payload', payload);
    try {
      yield put({ type: cons.GETUSER });

      const user = getUser();
      console.log('[SAGAS] watchGetUser, user:', user);
      yield put({ type: cons.GETUSER, user: user });
    } catch (error) {
      yield put({ type: cons.GETUSER });
      throw error;
    }
  });
}


export default [watchSignIn, watchSignOut, watchGetUser];
