const common = {
  'welcome.message': '<p>{{name}}, 歡迎使用</p>',
};

const stringTable = {
  '': 'test'
};

const finalResult = Object.assign(
  {},
  common,
  stringTable,
);

export default finalResult;
