import { fromJS } from 'immutable';
import { createAction } from 'redux-actions';

import * as cons from '../constants/notification';

const initialState = fromJS({
  notificationInit: false,
  notificationInitSuc: false,
  notificationInitErr: false,
  notificationListenInit: false,
  notificationListenSuc: false,
  notificationListenErr: false,
});

export default function reducer(state = initialState, action = {}) {
    console.log('action', action)
    switch (action.type) {
        case cons.NOTIFICATION_INIT: {
            return state.merge({
                notificationInit: true,
                notificationInitSuc: false,
                notificationInitErr: false,
            });
        }

        case cons.NOTIFICATION_INIT_SUC: {
            return state.merge({
                notifications: action.notifications,
                notificationInit: false,
                notificationInitSuc: true,
                notificationInitErr: false,
            });
        }

        case cons.NOTIFICATION_INIT_ERR: {
            return state.merge({
                notifications: undefined,
                notificationInit: false,
                notificationInitSuc: false,
                notificationInitErr: true,
            });
        }

        case cons.NOTIFICATION_LISTEN_UNREAD_INIT: {
            return state.merge({
                notificationListenInit: true,
                notificationListenSuc: false,
                notificationListenErr: false,
            });
        }

        case cons.NOTIFICATION_LISTEN_UNREAD_INIT_SUC: {
            return state.merge({
                unreadNotifications: action.notifications,
                notificationListenInit: false,
                notificationListenSuc: true,
                notificationListenErr: false,
            });
        }

        case cons.NOTIFICATION_LISTEN_UNREAD_INIT_ERR: {
            return state.merge({
                unreadNotifications: undefined,
                notificationListenInit: false,
                notificationListenSuc: false,
                notificationListenErr: true,
            });
        }

        default:
            return state;
    }
}

export function initNotification() {
  return (dispatch, getState) => {
    dispatch({ type: cons.NOTIFICATION_INIT });

    // silkrodeApi.init(__CODING_BAR_API_ENV__)
    //   .then(() => {
    //     console.log("reducer# initNotification");
    //     silkrodeApi.getNotificationService().getNotifications().then(notifications=>{
    //         const state = getState();
    //         const isInitSuc = state.app.get('notificationInitSuc');
    //
    //         if (!isInitSuc) {
    //           dispatch({ type: cons.NOTIFICATION_INIT_SUC, notifications });
    //         }
    //     });
    //   })
    //   .catch((e) => {
    //     console.error('init silkrodeApi notification error', e);
    //     dispatch(cons.NOTIFICATION_INIT_ERR);
    //   });
  };
}

export function initListenUnreadNotification() {
    return (dispatch, getState) => {
      dispatch({ type: cons.NOTIFICATION_LISTEN_UNREAD_INIT });
  
      // silkrodeApi.init(__CODING_BAR_API_ENV__)
      //   .then(() => {
      //       console.log("reducer# initListenUnreadNotification");
      //
      //       silkrodeApi.getNotificationService().listenUnreadNotifications({
      //           onUpdated(notifications) {
      //               console.log("listenUnreadNotifications# onUpdated# notifications:", notifications);
      //
      //               // refresh state if there is an message received
      //               dispatch({ type: cons.NOTIFICATION_LISTEN_UNREAD_INIT_SUC, notifications });
      //               silkrodeApi.getNotificationService().getNotifications().then(notifications=>{
      //                     dispatch({ type: cons.NOTIFICATION_INIT_SUC, notifications });
      //               });
      //           },
      //           onError(error) {
      //               console.log("listenUnreadNotifications# error:", error);
      //               dispatch({ type: cons.NOTIFICATION_LISTEN_UNREAD_INIT_ERR, error });
      //           }
      //       });
      //   })
      //   .catch((e) => {
      //       console.error('init silkrodeApi listenUnreadNotifications error', e);
      //       dispatch(cons.NOTIFICATION_LISTEN_UNREAD_INIT_ERR);
      //   });
    };
  }

export const getNotificationAction = createAction(cons.SAGA_GET_NOTIFICATION);
export const getNotificationListenUnreadAction = createAction(cons.SAGA_LISTEN_UNREAD_NOTIFICATION);
