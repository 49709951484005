import { post } from './HttpClient';
import { getConfig } from '../settings';

const ENV = "fut";
const URL_LOGIN = `http://${getConfig(ENV).authHost}/auth/signIn`;
const URL_REFRESH_TOKEN = `http://${getConfig(ENV).authHost}/auth/refreshToken`;
const URL_LOGOUT = `http://${getConfig(ENV).authHost}/auth/signOut`;
const URL_ACTIVATE = `http://${getConfig(ENV).authHost}/auth/activate`;
const URL_REQUEST_RESET_PASSWORD = `http://${getConfig(ENV).authHost}/auth/requestResetPassword`;
const URL_RESET_PASSWORD = `http://${getConfig(ENV).authHost}/auth/resetPassword`;
const URL_VALIDATE_PASSWORD_CODE = `http://${getConfig(ENV).authHost}/auth/validatePasswordCode`;

export function activate(code) {
  return post(URL_ACTIVATE, undefined,{code}).then((result) => {
    return Promise.resolve(result);
  });
};

export function validatePasswordCode(code) {
  return post(URL_VALIDATE_PASSWORD_CODE, {'Content-Type': 'application/json'},{code}).then((result) => {
    return Promise.resolve(result);
  });
};

export function resetPassword(code, password) {
  console.log("resetPassword");
  return post(URL_RESET_PASSWORD, {'Content-Type': 'application/json'},{code, password}).then((result) => {
    console.log("resetPassword", result);
    return Promise.resolve(result);
  });
};


export function requestResetPassword(email) {
  console.log("requestResetPassword");
  return post(URL_REQUEST_RESET_PASSWORD, {'Content-Type': 'application/json'},{email}).then((result) => {
    console.log("requestResetPassword", result);
    return Promise.resolve(result);
  });
};

export function signIn(userId, password) {
  return post(URL_LOGIN, undefined,{uid: userId, password: password}).then((result) => {

    console.log("signIn#", result);

    if (result.status !== "ok") {
      localStorage.clear();
      return Promise.reject("SignInError");
    }

    const user = {
      uid: userId,
      role: result.role,
      accessToken: result.accessToken,
      refreshToken: result.refreshToken,
      expiresIn: result.expiresIn,
      photo: "https://lh5.googleusercontent.com/-ZDjTW6LDn6Y/AAAAAAAAAAI/AAAAAAAAjDc/cMF3Veygbm0/photo.jpg",
    };

    console.log("signIn# successfully", user);

    localStorage.setItem("user", JSON.stringify(user));

    //setInterval(refreshToken, (resp.expiresIn/2)*1000);
    //setInterval(refreshToken, (resp.expiresIn/2)*1000);

    return Promise.resolve(user);
  });
};

export function refreshToken() {
  const user = getUser();
  return post(URL_REFRESH_TOKEN, {'Authorization': `Bearer ${user.accessToken}`,
    'Content-Type': 'application/json'},{refreshToken: user.refreshToken}).then((resp) => {
    user["accessToken"] = resp.accessToken;
    user["refreshToken"] = resp.refreshToken;
    localStorage.setItem("user", JSON.stringify(user))
    return Promise.resolve(user);
  });
};

export function getUser() {
  let user = localStorage.getItem("user");
  if (user) {
    return user
  }
  user = "user-hakka";
  localStorage.setItem("user", user);
  return user;
};

export function signOut() {
  console.log("signOut#signOut - before:", getUser());
  const user = getUser();
  if (!user) {
    localStorage.clear();
    return Promise.resolve(undefined);
  }
  return post(URL_LOGOUT, {'Authorization': `Bearer ${user.accessToken}`,
    'Content-Type': 'application/json'},{}).then((resp) => {
    console.log("signOut#signOut - done:", resp);
    localStorage.clear();
    return Promise.resolve(user);
  });
};

export function getRole(roles) {
  if (!roles || roles.length === 0) {
    return "unknown";
  }

  if (roles.find(role => role === 'admin') !== undefined) {
    return 'admin';
  }

  if (roles.find(role => role === 'editor') !== undefined) {
    return 'editor';
  }

  if (roles.find(role => role === 'sys_admin') !== undefined) {
    return 'sys_admin';
  }

  if (roles.find(role => role === 'group_admin') !== undefined) {
    return 'group_admin';
  }

  if (roles.find(role => role === 'user') !== undefined) {
    return 'user';
  }

  return 'unknown'
};
